






















import { SfImage } from '@storefront-ui/vue';
import {
  defineComponent, ref, onMounted, onUnmounted,
} from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  components: {
    SfImage,
  },
  setup(_, { root }) {
    let scTimer = null;
    const isPWA = root.$isPWA;

    const scY = ref(0);
    const handleScroll = () => {
      if (scTimer) return;
      scTimer = setTimeout(() => {
        scY.value = window.scrollY;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        clearTimeout(scTimer);
        scTimer = null;
      }, 100);
    };
    const toTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });
    return {
      scY,
      toTop,
      isPWA,
      addBasePath,
    };
  },
});
